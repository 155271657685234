.filter-row {
  color: $text-dark;
  padding: 2px 1rem;
  user-select: none;

  .inner-list {
    padding-left: 1rem;

    &:first-of-type {
      margin-top: 3px;
    }
  }

  label {
    display: block;
  }

  input {
    display: none;
  }

  .title {
    text-transform: capitalize;
  }

  svg {
    position: relative;
    top: 1px;
    margin-right: 2px;
    fill: $text-light;
    height: 14px;
    //transition: all .3s ease;
  }

  input:checked + svg {
    fill: $purple;
  }
}

.filter-row-title {
  border-radius: 3px;
  padding: 0.75rem 1rem;
  background: #f2f2f2;
  font-weight: 700;
  font-size: 18px;
}

.filter-rows-container {
  padding: 0.75rem 0;
}

.filter-group {
  //padding: 1rem;
  margin: 0 0 1rem 0;
}

.show-all {
  margin-top: 0.5rem;
  user-select: none;
  padding: 0 1rem;
  font-size: 14px;
  font-weight: 700;
  color: $purple;

  &:hover {
    color: lighten($purple, 10%);
  }
}

.input-filter-container {
  padding: 0.75rem 1rem 0.25rem 1rem;
  margin: 0 0 0.5rem 0;

  input {
    width: 100%;
    padding: 0.5rem;
  }
}
