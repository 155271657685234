.app-header {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
}

.top-header {
  font-size: 14px;
  background: #f7f7f7;

  .top-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
  }

  .links {
    display: none;

    @media all and (min-width: 768px) {
      display: flex;
      flex: 0 0 auto;
      justify-content: flex-end;
      align-items: center;
    }

    a {
      text-decoration: none;
      margin-left: 1.5rem;
      //color: purple;
    }

    button {
      margin-right: 1.5rem;
    }
  }
}

.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .left {
    display: flex;
  }
}

.logo {
  height: 34px;
  margin: 0 1rem 0 0;

  @media all and (min-width: 768px) {
    margin: 0 1rem 0 0;
    height: 56px;
  }
}

.navigation-row {
  flex: 1 1 auto;
  align-items: center;
  //  display: none;

  @media all and (min-width: 768px) {
    display: flex;
  }
}

.navigation-links {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem 0;

  button {
    margin-left: 2rem;
  }

  a,
  span {
    text-decoration: none;
    color: $text-light;
    transition: all 0.3s ease;
    margin-right: 1.5rem;
    font-size: 16px;
    user-select: none;

    @media all and (min-width: 768px) {
      margin-right: 1rem;
      font-size: 18px;
    }

    @media all and (min-width: 1280px) {
      margin-right: 2rem;
    }

    &:hover {
      opacity: 0.7;
      color: lighten($purple, 5%);
    }

    &.active {
      color: $purple;
      font-weight: 700;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  span {
    color: $text-light;
    opacity: 0.5;

    &:hover {
      color: $text-light;
      opacity: 0.5;
    }
  }
}

.search-wrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex: 0 1 auto;

  @media all and (min-width: 1280px) {
    margin-right: 2rem;
  }

  .input-container {
    position: relative;
    z-index: 600;
    width: 100%;

    .close-icon {
      position: absolute;
      top: 9px;
      right: 7px;
      z-index: 700;
      font-size: 25px;
      color: $text-light;
      transition: opacity 0.3s ease;

      &.hidden {
        opacity: 0;
      }

      &.visible {
        opacity: 1;
      }
    }
  }

  input {
    width: 100%;
    padding: 1rem;
    position: relative;
    z-index: 666;
    border: 1px solid $gray-border;
    border-radius: 3px;
    -webkit-appearance: none;
    height: 42px;
  }
}

.search-list-container {
  position: absolute;
  width: 100%;
  background: white;
  z-index: 666;
  left: 0;
  right: 0;
  top: 56px;

  @media all and (min-width: 768px) {
    max-width: 450px;
    top: 73px;
    left: auto;
    right: auto;
  }
}
