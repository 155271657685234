.wine-column-container {
  display: flex;
  flex-direction: column;

  @media all and (min-width: 1280px) {
    flex-direction: row;
    justify-content: space-between;
  }

  .wine-search-item {
    .row {
      width: 100%;
    }
  }

  .main-details-column {
    flex: 1 1 auto;
  }

  .similar-wines-column {
    @media all and (min-width: 1204px) {
      flex: 0 0 400px;
    }
  }
}

.back-button {
  margin-bottom: 2rem;
}

.wine-details {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .image-details-wrapper {
    display: flex;
  }

  img {
    max-height: 100%;
    max-width: 100%;
    align-self: baseline;
  }

  .image-container {
    order: 2;
    flex: 0 0 38%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    position: relative;
    max-height: 380px;

    img {
      margin: 0 auto;
    }
  }

  .copyright {
    text-align: center;
    padding: 0.5rem 0;
    font-size: 12px;
    color: $text-light;
  }

  .details-container {
    order: 1;
  }

  .details {
    h3 {
      margin: 0 0 0.5rem 0;

      + .rating-container {
        margin-bottom: 0.5rem;
      }
    }

    .price {
      font-weight: 700;
      margin: 0.25rem 0 1rem 0;
    }
  }

  .table {
    display: table;
    margin: 0 0 2rem 0;
    font-size: 14px;

    .table-row {
      display: table-row;

      &:nth-child(even) {
        background: #f2f2f2;
      }
    }

    .table-cell {
      padding: 5px;
      display: inline-block;

      &:first-child {
        width: 50%;
        font-weight: 600;
      }
    }
  }

  .review-stars {
    user-select: none;
    max-width: 260px;
    margin: 1rem 0;
  }

  .stars-row {
    display: flex;

    .amount {
      flex: 0 0 60px;
      text-align: right;
      padding-top: 6px;
    }
  }

  .stars-container {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .star {
      display: flex;
      flex: 0 0 20%;
      justify-content: center;
      align-items: center;

      svg {
        width: 28px;
        height: 28px;
        transition: transform 0.5s ease;

        &.highlight {
          fill: $star-orange;
        }

        &.five-out-five {
          transform: scale(1.4);
        }
      }
    }
  }

  .review {
    margin: 2rem 0 0 0;

    h3 {
      margin: 1rem 0;
    }
  }

  label {
    display: block;
    margin: 0 0 0.5rem 0;
    user-select: none;

    input {
      position: relative;
      top: -1px;
      margin-right: 6px;
    }
  }

  .edit-review {
    position: relative;
    background: peachpuff;
    margin: 0 0 2rem 0;
    padding: 1rem;
  }

  blockquote {
    position: relative;
    font-style: italic;
    margin: 1rem 0;
    color: $text-light;
    max-width: 300px;
    padding-right: 2rem;

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }

    button {
      position: absolute;
      right: 0;
      top: -7px;
    }
  }

  .links {
    text-align: center;
    font-size: 13px;

    a {
      color: $star-orange;
    }
  }

  .sopii {
    display: inline-flex;
    margin: 0.25rem 2rem 0 0;
    align-items: center;
    font-size: 13px;
    font-weight: bold;

    svg {
      margin-right: 0.5rem;
      color: $purple;
      font-size: 20px;
    }
  }

  .review-item {
    position: relative;
    padding-right: 3rem;
    margin: 0 0 1rem 0;

    .date {
      font-size: 14px;
      color: $text-light;
    }

    .rating-container {
      padding: 0.25rem 0 0.5rem 0;
    }

    button {
      position: absolute;
      right: 0;
      top: 1rem;
    }

    + .review-item {
      //margin: 1rem 0 0 0;
    }
  }

  .leave-comment {
    h3 {
      margin-bottom: 1rem;
    }

    textarea {
      width: 100%;
      margin-top: 1rem;
      border-radius: 3px;
      border: 1px solid $gray-border;
      min-height: 50px;
    }

    button {
      margin-top: 0.5rem;
    }
  }

  .tabbed-content {
    margin-top: 2rem;

    h3 {
      margin-top: 0;
    }

    .tabs {
      margin-bottom: -1px;
    }

    .tab {
      user-select: none;
      padding: 0.5rem;
      font-size: 14px;
      display: inline-block;
      border: 1px solid $gray-border;
      border-bottom: none;
      background: #ddd;

      &.active {
        background: white;
        color: $purple;
        font-weight: 600;
      }

      + .tab {
        margin-left: -1px;
      }
    }

    .tab-content {
      padding: 1rem;
      border: 1px solid $gray-border;
      border-radius: 3px;

      h4 {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }

  .sopii-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sopii-bar-container {
    overflow: hidden;
    border-radius: 3px;
    height: 6px;
    flex: 0 0 calc(100% - 40px);
    position: relative;
    background: #ddd;

    div {
      position: absolute;
      overflow: hidden;
      border-radius: 3px;
      top: 0;
      left: 0;
      display: inline-block;
      height: 100%;
      background: $purple;
    }
  }
}

.review-block {
  margin: 0 0 2rem 0;

  h3 {
    margin: 0 0 1rem 0;
  }

  .star-row {
    display: flex;
    align-items: center;
    padding: 2px 0;

    .stars {
      width: 55px;
      font-size: 10px;
      display: flex;
      flex-direction: row-reverse;

      color: #bbb;

      &.has-reviews {
        color: $star-orange;
      }
    }

    .rating {
      padding-left: 5px;
    }
  }
}

.review-tables-container {
  display: flex;
  align-items: center;

  .bars-table {
    flex: 0 0 200px;
    margin-left: 1rem;
    font-size: 14px;

    h4 {
      display: flex;
      align-items: center;

      &:last-of-type {
        margin-top: 1rem;
      }

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}

.leave-or-edit-review {
  .rating-labels-container {
    overflow: hidden;
    transition: max-height 1s ease;

    &.expanded {
      max-height: 200px;
    }

    &.collapsed {
      max-height: 0;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    margin: 1rem 0 0 0;
    justify-content: space-between;

    @media all and (min-width: 768px) {
      justify-content: initial;

      button {
        margin-right: 1rem;
      }
    }

    button {
      padding: 6px 10px;
      font-size: 13px;
    }
  }

  //oma arvio
  .rating-container {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  .date {
    color: $text-light;
    font-size: 13px;
    margin-bottom: 0.25rem;
  }

  .current-user-comments {
    margin: 1rem 0;
  }

  .add-comment-button {
    font-size: 13px;
    display: flex;
    font-weight: bold;
    color: $purple;

    svg {
      margin-right: 0.25rem;
    }
  }

  .add-bookmark,
  .delete-bookmark {
    svg {
      color: $star-orange;
    }
  }
}

.please-login {
  margin: 2rem 0;
  padding: 1rem;
  background: #f2f2f2;
  max-width: 400px;

  h4 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    line-height: 1.25;
    font-size: 14px;
  }
}

.wine-grid-item {
  .wine-selection {
    bottom: 55px;
    left: 8px;
    //bottom: 30px;
    right: auto;
  }
}

.wine-selection {
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  background: white;
  text-align: center;
  color: purple;
  border: 1px solid purple;
  position: absolute;
  right: 0;

  &.vakiovalikoima {
    display: none;
  }

  &.tilausvalikoima {
    color: orangered;
    border: 1px solid orangered;
    background: white;
  }
}
