.wine-header {
  //display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0 0.25rem 0;

  .wine-country {
    font-size: 13px;
    color: $text-light;
    font-weight: normal;
    display: inline-block;
  }

  .wine-name-container {
    min-height: 32px;
    margin-top: 0.25rem;
  }

  .wine-name {
    font-weight: bold;
    margin-bottom: 0.25rem;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

.wine-description-label {
  color: white;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 11px;
  margin-bottom: 3px;
  text-transform: capitalize;

  &.erittäin-täyteläinen {
    background: darkmagenta;
  }

  &.täyteläinen {
    background: mediumvioletred;
  }

  &.keskitäyteläinen {
    background: palevioletred;
  }

  &.kevyt {
    background: lighten(palevioletred, 20%);
  }

  &.erittäin-kuiva {
    background: seagreen;
  }

  &.kuiva {
    background: lightseagreen;
  }

  &.puolikuiva {
    background: limegreen;
  }

  &.puolimakea {
    background: yellowgreen;
  }

  &.makea {
    background: greenyellow;
    color: darkgreen;
  }
}

.wine-price {
  font-size: 24px;
  font-weight: 600;

  .cents {
    font-size: 12px;
    margin-left: 4px;
    position: relative;
    top: -9px;
    color: #999;
    border-bottom: 1px solid #999;
  }
}

.wine-icon {
  width: 20px;
  height: 20px;
}

.bookmark-icon {
  position: absolute;
  top: -9px;
  right: 0.5rem;
  fill: $star-orange;
  height: 32px;
}
