.wine-list-item {
  position: relative;
  text-decoration: none;
  color: $text-dark;
  overflow: hidden;

  img {
    //display: block;
    //max-width: 100%;
  }
}

.wine-icons {
  display: none;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background: rgba(255, 255, 255, 0.5);

  img {
    margin: 0 !important;

    + img {
      margin-top: 0.5rem;
    }
  }
}

.wine-grid-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.2s ease;
  width: calc(50% - 0.2rem);
  margin: 0 0 0.5rem 0;
  min-height: 277px;

  .wine-name {
    font-size: 14px;

    @media all and (min-width: 1024px) {
      font-size: 16px;
    }
  }

  @media all and (min-width: 768px) {
    margin: 0 0 1rem 0;
    width: calc(33% - 0.5rem);
  }

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
  }

  .score {
    margin-top: 3px;
  }

  img {
    margin: 0 auto 1.5rem auto;
  }
}

.wine-search-item {
  display: flex;
  border-bottom: 1px solid $gray-border;
  padding: 0.5rem 0;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // width: 100%;
  }

  .image-container {
    max-height: 100px;
    flex: 0 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 90px;
      align-self: center;
      max-width: 100%;
    }
  }

  .text-container {
    flex: 1;
    position: relative;
  }

  .wine-name {
    font-size: 14px;
  }
}
