.wine-list-title {
  margin-bottom: 1.5rem;

  &:first-letter {
    text-transform: uppercase;
  }

  .filters-toggle {
    position: relative;
    right: auto;
    bottom: auto;
    display: inline-flex;
    font-size: 14px;
    width: 34px;
    height: 34px;
    margin-left: 0.5rem;

    @media all and (min-width: 768px) {
      margin-top: -10px;
    }
  }
}

.price-inputs {
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;

  input {
    width: calc(50% - 0.5rem);
  }
}

.list-container {
  //overflow: hidden;
  display: flex;
  flex-direction: row;

  .list {
    flex: 1;
  }

  .filters {
    background: white;
    z-index: 700;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-x: scroll;
    transition: transform 0.2s ease;
    width: calc(100% - 50px);
    max-width: 280px;
    padding-bottom: 80px;

    &.hidden {
      transform: translateX(100%);
      z-index: 0;

      @media all and (min-width: 768px) {
        transform: none;
      }
    }

    &.visible {
      transform: translateX(0);
    }

    @media all and (min-width: 768px) {
      position: relative;
      flex: 0 0 280px;
      margin-right: 1rem;
    }
  }
}

.filters-panel-title {
  padding: 1rem;
  min-height: 38px;

  .wrapper {
    display: flex;
  }

  @media all and (min-width: 768px) {
    padding: 0 0 0.5rem 0;
  }

  button {
    margin-left: 1rem;
  }
}

.display-wines-button {
  position: fixed;
  bottom: -3px;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 800;
  max-width: 100%;
  transition: all 0.5s ease;
  background: $purple;
  color: white;
  border: none;
  padding: 1.5rem 1rem;
  font-weight: bold;
  border-radius: 0;
  text-align: center;

  &:hover {
    color: white;
    border: none;
    background: lighten($purple, 5%);
  }

  &.visible {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
