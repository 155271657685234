@import "reset";
@import "variables";
@import "../components/filter-row/filter-row";
@import "../components/header/header";
@import "../components/list-with-filters/list-with-filters";
@import "../components/wine-list-item/wine-list-item";
@import "../components/wine-details/wine-details";
@import "../components/wine-list-item/wine-components";

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

body {
  font-family: sans-serif;
  background: white;
}

#root {
  //padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

.page-container {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.global-menu {
  border-right: 1px solid $gray-border;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 50px);
  max-width: 280px;
  //display: flex;
  //flex-direction: column;
  background: white;
  z-index: 800;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.closed {
    transform: translateX(100%);
  }

  &.open {
    transform: translateX(0%);
  }

  a {
    text-decoration: none;
  }

  > div:first-child {
    a,
    span {
      display: block;
      text-decoration: none;
      font-size: 18px;
      padding: 1rem;
      color: $text-light;

      &:hover {
        color: lighten($purple, 5%);
      }

      &.active {
        color: $purple;
        font-weight: bold;
        border-left: 4px solid $purple;
      }
    }
  }

  > span {
    opacity: 0.4;

    &:hover {
      color: $text-light;
    }
  }

  button {
    margin: 1rem;
  }

  .assistive-links {
    flex-direction: column;
    padding-bottom: 3rem;
  }
}

.page-content {
  //padding: 1rem 0;
}

.wines-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media all and (min-width: 768px) {
    justify-content: space-between;
    flex-wrap: wrap;
    width: calc(100% - 1rem);
  }
}

.sort {
  display: flex;
  user-select: none;
  margin-bottom: 1rem;

  div {
    display: inline-block;
    margin-right: 1rem;

    &.active {
      font-weight: bold;
      color: purple;
    }
  }
}

.wine-review-list {
  padding: 1rem 0;
}

.reviews-container {
  display: flex;
}

.rating-container {
  color: $star-orange;
  display: flex;
  align-items: center;
}

.reviewed-by-me {
  //color: $star-orange;
  color: $star-5-5;

  .bad {
    //color: lighten(orange, 15%);
  }

  .good {
  }

  //&.better {
  //  color: darkorange;
  //}
  &.better,
  &.best {
  }
}

.exact-rating {
  color: $text-light;
  font-size: 13px;
  margin-left: 0.5rem;
}

.reviewed-by-others {
  color: #666;
}

q,
blockquote {
  quotes: "“" "”" "‘" "’";
}

button {
  max-width: 240px;
  display: flex;
  align-items: center;
  color: $purple;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: 14px;
  padding: 6px 12px;

  &.small {
    font-size: 13px;
    padding: 3px 8px;
  }

  &.delete {
    background: indianred;
    border: 1px solid indianred;

    color: white;

    &:hover {
      color: white;
    }
  }

  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    color: lighten($purple, 15%);
    border: 1px solid lighten($purple, 15%);
  }

  &:disabled {
    background: #ddd;
    opacity: 0.25;
  }

  &.icon-red {
    svg {
      fill: $purple;
    }
  }

  &.round-icon {
    background: $gray-border;
    color: #333;
    border: none;
    border-radius: 40px;
    height: 32px;
    width: 32px;
    display: flex;
    font-size: 18px;
    padding: 0;
    align-items: center;
    justify-content: center;

    &.edit {
      svg {
        position: relative;
        left: 1px;
        top: -2px;
      }
    }
  }

  &.icon-button {
    svg {
      margin-right: 0.5rem;
    }
  }
}

.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 400;
  transition: background 0.3s ease, backdrop-filter 0.5s ease;

  &.visible {
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2px);
  }

  &.hidden {
    pointer-events: none;
    background: transparent;
  }
}

input,
textarea {
  font-size: 16px;
}

.amount {
  color: $text-light;
  font-weight: 200;
}

.menu-overlay {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 650;
  transition: all 0.3s ease;

  &.open {
    opacity: 1;
  }

  &.closed {
    pointer-events: none;
  }
}

.mobile-menu-button {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: $purple;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  right: 2rem;
  z-index: 600;
  border: 1px solid purple;
  border-radius: 30px;

  @media all and (min-width: 768px) {
    display: none !important;
  }
}

.hamburger-container {
  bottom: 4rem;
}

.filters-toggle {
  bottom: 7rem;
  font-size: 17px;

  .active-filters-amount {
    font-size: 11px;
    position: absolute;
    top: -3px;
    right: -3px;
    background: purple;
    color: white;
    width: 14px;
    height: 14px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.assistive-links {
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;

  .assistive-link {
    font-size: 14px;
    padding: 0.5rem;
    display: inline-flex;
    color: #666;

    &.active {
      color: purple;
    }
  }
}

.static-page {
  padding: 0 1rem;

  h1 {
    font-weight: bold;
    font-size: 28px;
    margin: 0 0 2rem 0;
  }

  p {
    margin: 0 0 1rem 0;
    max-width: 500px;
    color: #333;
    line-height: 1.3;
  }
}

mark {
  &.highlight {
    background: $star-orange;
  }
}
